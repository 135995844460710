import React, { Component } from 'react';
import $ from 'jquery';
import { Footer } from './Footer';
import { ItemPodCast } from './component/ItemPodCast';
import Pagination from "react-js-pagination";
import { PesquisaPod } from './component/PesquisaPod';

// jshint ignore: start
class Podcastspesquisa extends Component {

  constructor() {
    super();
    this.state = {podcasts : [], carregamento: false,activePage: 1,totalitens:0};
    this.handlePageChange = this.handlePageChange.bind(this);
}
componentWillMount(){
     var me = this;
  
  
    $.ajax({
      url:"https://promecast.com.br/api/podcast/listapesquisatotal/" + me.props.params.categoria + "/" + me.props.params.palavra ,
      dataType: 'json',
      success:function(resposta){       
      me.setState({totalitens:resposta[0].total}) ;                                                                                     
      }
    }
    );

      this.carregaItensPagina(1);
  }


  getParsedDate(date){
    date = String(date).split(' ');
    var days = String(date[0]).split('-');       ;
    return days[2] + '/' + days[1] + '/' + days[0] ;
  }
  carregaItensPagina(pagina){
    var me = this;
    $.ajax({
      url:"https://promecast.com.br/api/podcast/listapesquisa/" + me.props.params.categoria + "/" + me.props.params.palavra + "/" + pagina,
      dataType: 'json',
      success:function(listapod){       

          me.setState({podcasts:listapod,carregamento: true});                                                                                    
      }
    }
    );
  }
  handlePageChange(pageNumber) {    
  
    this.setState({activePage: pageNumber});
    this.carregaItensPagina(pageNumber);
  }
  render() {
    
   
    return (

<any>  

    <main id="main" className="main">
    <header className="page-header" style={{'backgroundImage' : 'url(assets/image/home3.png)'}}>
				<div className="page-header-inner">
					<div className="container">						
					
						<div className="page-header-content text-center">
							<h2>Episódios que contém a {this.props.params.categoria} {this.props.params.palavra} </h2>
						</div>

					</div>
				</div>
			</header>	
	<section id="#episodes" className="section-positive">
  <PesquisaPod mostrarTitulo="false"></PesquisaPod><br></br>
				<div className="container">		
        				
					<div className="row">
                      {
            this.state.podcasts.map((p,index)=>{
              return (
             
           <ItemPodCast 
           key={p.idpodcast}
            index={index}
            tipo="lista"
            nome={p.nome}           
            descricao={p.miniartigo}
            data={ this.getParsedDate(p.data)}
            tempo={p.tempo}
            idpodcast={p.idpodcast}
            nomecategoria={p.nomecategoria}
            lnkamigavel={p.lnkamigavel}
            autor={p.autor}
           >             
           </ItemPodCast>
                                       
              );})
              }
                    </div>
                </div>

                <div className="col-sm-12 text-center mb-50">
                { this.state.carregamento &&
                <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={5}
          totalItemsCount={this.state.totalitens}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
        }
						</div>

         

    </section>
    </main>
<Footer></Footer>
    
    
</any>
  
    );
  }
}

export default Podcastspesquisa;
