import React, { Component } from 'react';
import { PodPrincipal } from './component/PodPrincipal';
import $ from 'jquery';
import { Footer } from './Footer';
import { ItemPodCast } from './component/ItemPodCast';
import Pagination from "react-js-pagination";
import { PesquisaPod } from './component/PesquisaPod';

// jshint ignore: start
class Podcasts extends Component {

  constructor() {
    super();
    this.state = {podcasts : [],ultimo : [], caregamento: false,activePage: 1,totalitens:0};
    this.handlePageChange = this.handlePageChange.bind(this);
}
componentWillMount(){
     var me = this;
      
    $.ajax({
        url:"https://promecast.com.br/api/podcast/site/top/1",
        dataType: 'json',
        success:function(resposta){         
            me.setState({ultimo:resposta,carregamento: true});                                                                                    
        }
      }
    );
    $.ajax({
      url:"https://promecast.com.br/api/podcast/listapesquisatotal/top/a",
      dataType: 'json',
      success:function(resposta){       
      me.setState({totalitens:resposta[0].total}) ;                                                                                     
      }
    }
  );
    

      this.carregaItensPagina(1);
  }


  getParsedDate(date){
    date = String(date).split(' ');
    var days = String(date[0]).split('-');       ;
    return days[2] + '/' + days[1] + '/' + days[0] ;
  }
  carregaItensPagina(pagina){
    
    var me = this;
    console.log(me.state.activePage);
    $.ajax({
      url:"https://promecast.com.br/api/podcast/listapesquisa/top/a/" + pagina,
      dataType: 'json',
      success:function(listapod){       
          me.setState({podcasts:listapod});                                                                                    
      }
    }
    );
  }
  handlePageChange(pageNumber) {    
  //console.log(pageNumber);
    this.setState({activePage: pageNumber});
    this.carregaItensPagina(pageNumber);   
     
  }
  render() {
    
    let primeiropod = this.state.ultimo[0]; 
    return (

<any>  
{ this.state.carregamento &&
      	<PodPrincipal                  
            nome={primeiropod.nome}           
            descricao={primeiropod.miniartigo}
            data={ this.getParsedDate(primeiropod.data)}
            tempo={primeiropod.tempo}
            idpodcast={primeiropod.idpodcast}
            nomecategoria={primeiropod.nomecategoria}
            lnkamigavel={primeiropod.lnkamigavel}
            autor={primeiropod.autor}
        ></PodPrincipal>
    }
    <main id="main" className="main">
 
	<section id="#episodes" className="section-positive">
     <PesquisaPod mostrarTitulo="true"></PesquisaPod>
				<div className="container">				
       
        				

					<h2 className="title-default">Episódios</h2>
					
					<div className="row">
                      {
            this.state.podcasts.map((p,index)=>{
              return (
             
           <ItemPodCast 
           key={p.idpodcast}
            index={index}
            tipo="lista"
            nome={p.nome}           
            descricao={p.miniartigo}
            data={ this.getParsedDate(p.data)}
            tempo={p.tempo}
            idpodcast={p.idpodcast}
            nomecategoria={p.nomecategoria}
            lnkamigavel={p.lnkamigavel}
            autor={p.autor}
           >             
           </ItemPodCast>
                                       
              );})
              }
            </div>
    
                </div>

                <div className="col-sm-12 text-center mb-50">
                <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={5}
          totalItemsCount={this.state.totalitens}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
						</div>

         

    </section>
    </main>
<Footer></Footer>
    
    
</any>
  
    );
  }
}

export default Podcasts;
