import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router,Route,browserHistory} from 'react-router';
import Podcasts from './Podcasts';
import { SobreNos } from './SobreNos';
import { OQueE } from './OQueE';
import { Contato } from './Contato';
import { Podcast } from './Podcast';
import Podcastspesquisa  from './Podcastspesquisa';
// jshint ignore: start
ReactDOM.render(
    (<Router history={browserHistory}>
        <Route path="/" component={App}/>
        <Route path="/podcast" component={Podcasts}/>  
        <Route path="/podcast/:lnkamigavel" component={Podcast}/>   
        <Route path="/podcast/:idpodcast/:lnkamigavel" component={Podcast}/>           
        <Route path="/podcastpes/:categoria/:palavra" component={Podcastspesquisa}/>   
        <Route path="/oquee" component={OQueE}/>     
        <Route path="/sobrenos" component={SobreNos}/>     
        <Route path="/contato" component={Contato}/>     
       
      </Router>)
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
