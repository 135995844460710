import React, { Component } from 'react';
import { Footer } from './Footer';



// jshint ignore: start
export class Contato extends Component {

  render() {
 
    return (
    <any>
        <main id="main" className="main">
		        
        <header className="page-header" style={{'background-image': 'url(assets/img/single-background.jpg)'}}>
            <div className="page-header-inner">
                <div className="container">

                    
                    <ol className="breadcrumb" >
                        <li itemprop="itemListElement"><a href="/">Home</a></li>
                        <li itemprop="itemListElement"><a href="/contato">Contato</a></li>
                    </ol>

                    
                    <div className="page-header-content text-center">
                        <h2>Contato</h2>
                    </div>

                </div>
            </div>
        </header>
                
        <section className="section-negative">
            <div className="container">
                        
                <div className="page-content mt-60">
                                
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 text-center">
                            <h2 className="title-default mb-10">Mande um email</h2>                            
                        </div>
                    </div>
                                    
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <form action="mail.php" method="POST" id="form-contact" className="form-contact form-validate">
                                <fieldset>
                                    <div className="mb-20">
                                        <label for="name" className="label-control">Nome* :</label>
                                        <input type="text" name="name" className="form-control" id="name" required />
                                    </div>
                                    <div className="mb-20">
                                        <label for="email" className="label-control">Email* :</label>
                                        <input type="email" name="email" className="form-control" id="email" required />
                                    </div>
                                    <div className="mb-20">
                                        <label for="subject" className="label-control">Titulo* :</label>
                                        <input type="text" name="subject" className="form-control" id="subject" required />
                                    </div>
                                    <div className="mb-20">
                                        <label for="message" className="label-control">Mensagem* :</label>
                                        <textarea name="message" id="message" rows="6" className="form-control" required></textarea>
                                    </div>
                                    <div className="mb-20 text-right">
                                        <input type="submit" className="btn btn-success btn-lg" value="Enviar" />
                                    </div>
                                    <div className="form-callback success">
                                        <p>Envio realizado com sucesso.</p>
                                    </div>
                                    <div className="form-callback error">
                                        <p>Erro no envio.</p>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
     
    </main>
        
        <Footer></Footer>
				


    </any>
    );
  }
}




