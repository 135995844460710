import React, { Component } from "react";
import { Footer } from "./Footer";
import { CardPessoa } from "./component/CardPessoa";
import $ from "jquery";

// jshint ignore: start
export class SobreNos extends Component {
  constructor() {
    super();
    this.state = { pessoas: [] };
    this.id = 0;
  }
  componentDidMount() {
    var me = this;
    $.ajax({
      url: "https://promecast.com.br/api/pessoa/listaquemsomos",
      dataType: "json",
      success: function (resposta) {
        me.setState({ pessoas: resposta });
      },
    });
  }

  render() {
    return (
      <any>
        <main id="main" className="main">
          <header
            className="page-header"
            style={{
              "background-image": "url(assets/img/single-background.jpg)",
            }}
          >
            <div className="page-header-inner">
              <div className="container">
                <ol className="breadcrumb">
                  <li itemprop="itemListElement">
                    <a href="/">Home</a>
                  </li>
                  <li itemprop="itemListElement">
                    <a href="/sobrenos">Sobre Nós</a>
                  </li>
                </ol>
                <div className="page-header-content text-center">
                  <h2>Sobre nós</h2>
                </div>
              </div>
            </div>
          </header>
          <section className="section-negative">
            <div className="page-content">
              <div className="container">
                <h2 className="title-default mb-30">Projeto</h2>
                <div className="row">
                  <div className="col-md-12 mb-20">
                    <p>
                      Promescat nasceu no coração do Rafael Lima em meados de
                      2012 devido o hábito de ouvir podcasts. Após conversar com
                      seu amigo Enos Oliveira, decidiram compartilhar com outras
                      pessoas que poderiam somar para o desenvolvimento da
                      ideia, mas o tempo foi passando, as conversas foram
                      esfriando e o projeto foi engavetado, vindo a tona
                      novamente em 2017 com apoio de um grande amigo que mora
                      nos EUA, que por algumas notas verdes de dólares forneceu
                      o material essencial para início. No dia 01 de abril,
                      parece até mentira, lançamos nosso primeiro trabalho e com
                      integração na RUMAP Paulistana Leste, decidimos levar um
                      pouco mais de conhecimento, cultura e teologia aos
                      ouvintes de uma forma bem descontraída.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <section id="about-us" className="section-negative">
          <div className="container">
            <h2 className="title-default text-center">Quem nos somos...</h2>

            <div className="row mb-30">
              {this.state.pessoas.map((p) => {
                return (
                  <CardPessoa
                    key={p.idpessoa}
                    idpessoa={p.idpessoa}
                  ></CardPessoa>
                );
              })}
            </div>
          </div>
        </section>

        <Footer></Footer>
      </any>
    );
  }
}
