
import React, { Component } from 'react';

// jshint ignore: start
export class PesquisaPod extends Component {
  constructor() {
    super();
    this.state = { palavra: "" };
    this.pesquisar = this.pesquisar.bind(this);
    this.setPalavra = this.setPalavra.bind(this);
  }
  pesquisar(evento) {
    evento.preventDefault();
    window.location.assign("/podcastpes/palavras/" + this.state.palavra);


  }
  setPalavra(evento) {
    // console.log(evento.target.value);
    this.setState({ palavra: evento.target.value });
  }
  render() {
    console.log(this.props.mostrarTitulo);
    return (
      <div className="container" >
        <form method="post" onSubmit={this.pesquisar} className="form-search  text-center">
          <div className="text-center">
            <div className="col-md-11 nopadding">
              <input type="search" name="search" className="form-control noborder" placeholder="Pesquisar" value={this.state.palavra} onChange={this.setPalavra} required />
            </div>
            <div className="col-md-1 nopadding">
              <button className="btn btn-success btn-lg btn-block"><i class="fa fa-search"></i></button>
            </div>
          </div>
        </form>

      </div>
    )
  }
}