import React, { Component } from 'react';
import $ from 'jquery';
import { CardPessoa } from './component/CardPessoa';
import { Footer } from './Footer';

import ReactPlayer from 'react-player'

// jshint ignore: start
export class Podcast extends Component {

  constructor() {
    super();
    this.state = {podcast : [],anterior:[],sucessor:[],tags:[],pessoas:[], caregamento: false};
    this.id = 0;
}
componentDidMount(){
     var me = this;     
     
     const lnkamigavel = me.props.params.lnkamigavel;
     
     
     $.ajax({
        url:"https://promecast.com.br/api/podcast/site/seleciona/" + lnkamigavel,
        dataType: 'json',
        success:function(resposta){  
            console.log(resposta)
            const idpodcast = resposta[0].idpodcast;
           

            $.ajax({
                url:"https://promecast.com.br/api/podcast/site/seleciona/anterior/" + idpodcast,
                dataType: 'json',
                success:function(resposta){       
             
                    me.setState({anterior:resposta[0]});      
                                                     
                }
              }
            );
            $.ajax({
                url:"https://promecast.com.br/api/podcast/site/seleciona/sucessor/" + idpodcast,
                dataType: 'json',
                success:function(resposta){       
             
                    me.setState({sucessor:resposta[0]});      
                                                      
                }
              }
            );
        
            $.ajax({
                url:"https://promecast.com.br/api/podcastpessoa/lista/" + idpodcast,
                dataType: 'json',
                success:function(resposta){       
             
                    me.setState({pessoas:resposta});      
                                                             
                }
              }
            );
            $.ajax({
                url:"https://promecast.com.br/api/tags/lista/" + idpodcast,
                dataType: 'json',
                success:function(resposta){       
             
                    me.setState({tags:resposta});                                                                 
                }
              }
            );
			

            me.setState({podcast:resposta[0]});      
                                 
            document.title = "Promecast - " + resposta[0].nome ; 
        }
      }
    );
    
    
    
 
  }
  getParsedDate(date){
    date = String(date).split(' ');
    var days = String(date[0]).split('-');       ;
    return days[2] + '/' + days[1] + '/' + days[0] ;
  }
  render() {
 


    return (

<any>  

<main id="main" className="main">

<header className="page-header-podcast" style={{'backgroundImage': 'url(https://www.promecast.com.br/arquivo/podcast' + this.state.podcast.idpodcast + '.jpg)'}}>
    <div className="page-header-inner">
        <div className="container">

            <ol className="breadcrumb" >
                <li >
                    <a href="/">Home</a>
                </li>
                <li >
                    <a href="/podcast">Podcast</a>
                </li>
            </ol>

            <div className="page-header-content text-center">
                <h2>{this.state.podcast.nome}</h2>
                <p dangerouslySetInnerHTML={{__html: this.state.podcast.miniartigo}}></p>
            </div>

        </div>
    </div>
</header>

    <div className="podcast-player">
    <div className="podcast-player-inner">
            <div className="container">			
            <ReactPlayer url={'https://www.promecast.com.br/arquivo/audio' + this.state.podcast.idpodcast + '.mp3'}  height="50px" width="" controls 	 />
           
            </div>
        </div>
</div>		
<div className="podcast-meta-element">
    <span className="podcast-meta-item">
        <i className="fa fa-calendar"></i> {this.getParsedDate(this.state.podcast.data)}</span>
    <span className="podcast-meta-item">
        <i className="fa fa-clock-o"></i> {this.state.podcast.tempo} minutos</span>
        <span className="podcast-meta-item">
        <i className="fa fa-user"></i> {this.state.podcast.autor}</span>
    <a className="podcast-meta-item" href={'/podcastpes/categoria/' + this.state.podcast.nomecategoria}>
        <i className="fa fa-tag"></i> {this.state.podcast.nomecategoria}</a>
    <a className="podcast-meta-item" href={'/arquivo/audio' +  this.state.podcast.idpodcast + '.mp3'} download={'audio' +  this.state.podcast.idpodcast + '.mp3'}>
        <i className="fa fa-download"></i> Download (MP3)</a>
</div>
<div className="section-negative">
    <div className="container">

        <div className="row mt-70 mb-50">					
            <div className="col-md-8 mb-50">					
                <div className="page-content">	<p dangerouslySetInnerHTML={{__html: this.state.podcast.artigo}} >
               
                        </p>                
                </div>

            </div>		
            <div className="col-md-4">					
                <div className="page-sidebar">						
                    <div className="sidebar-widget">
                        <h2 className="title-separator">Compartilhe o podcast</h2>
                        <ul className="social-list colored">
                        <li className="social-item facebook">
                        <a href={'https://www.facebook.com/sharer/sharer.php?u=https://www.promecast.com.br/podcast/' + this.props.params.lnkamigavel} target="_blank">
                        <i className="fa fa-facebook"></i>
                        </a></li>
                            <li className="social-item email">
                                <a href="mailto:promecast@promecast.com.br" >
                                    <i className="fa fa-envelope"></i>
                                </a>
                            </li>
                            <li className="social-item twitter"><a href={'https://twitter.com/home?status=https://www.promecast.com.br/podcast/' + this.props.params.lnkamigavel} target="_blank"><i className="fa fa-twitter"></i></a></li>
                            <li className="social-item google"><a href={'https://plus.google.com/share?url=https://www.promecast.com.br/podcast/' + this.props.params.lnkamigavel} target="_blank"><i className="fa fa-google-plus"></i></a></li>
                        </ul>
                    </div>
            
                    <div className="sidebar-widget">
                        <h2 className="title-separator">Tags</h2>
                        <ul className="tag-list">
                        {
              this.state.tags.map((p)=>{
              return (
                <li className="tag-item" key={p.descricao}>
                                <a href={'/podcastpes/tag/' + p.descricao} rel="tag">{p.descricao}</a>
                            </li>
              );
            })}                                                  
                        </ul>
                    </div>

                </div>

            </div>

        </div>
        <section id="about-us" className="section-negative">
            <div className="container">					
                <h2 className="title-default text-center">Quem falou...</h2>

                <div className="row mb-30">

                 {
              this.state.pessoas.map((p)=>{
              return (
                <CardPessoa  key={p.idpessoa} idpessoa={p.idpessoa}>
                </CardPessoa>
              );
            })}

                 
            
        

                </div>
               

            </div>
        </section>
        <hr />

        
        <div className="page-pagination">
            {this.state.anterior !== undefined &&
            <a href={'/podcast/'  + this.state.anterior.lnkamigavel } className="page-pagination-prev">
                <i className="fa fa-long-arrow-left"></i>
                <span>{this.getParsedDate(this.state.anterior.data)}</span>
                <p>{this.state.anterior.descricao}</p>
            </a>}
            {this.state.sucessor !== undefined &&
            <a href={'/podcast/'  + this.state.sucessor.lnkamigavel }  className="page-pagination-next">
                            <i className="fa fa-long-arrow-right"></i>
                            <span>{this.getParsedDate(this.state.sucessor.data)}</span>
                            <p>{this.state.sucessor.descricao}</p>
                        </a>
            }
        </div>

    
        <hr />
    
        <hr />

    </div>
</div>

</main>
    <Footer></Footer>
</any>
  
    );
  }
}


