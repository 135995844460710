
import React, { Component } from 'react';

import ReactPlayer from 'react-player'

// jshint ignore: start
export class PodPrincipal extends Component {


  render() {

    return (
      <section className="podcast-hero" style={{ 'backgroundImage': 'url(https://www.promecast.com.br/arquivo/podcast' + this.props.idpodcast + '.jpg)' }} >
        <div className="podcast-hero-inner">

          <div className="container">
            <div className="podcast-hero-content">

              <span className="podcast-hero-date">{this.props.data}</span>
              <h2 className="podcast-hero-title"><a href={"/podcast/" + this.props.lnkamigavel}>{this.props.nome}</a></h2>
              <ul className="podcast-hero-meta">
                <li className="item"><a href={"/podcast/" + this.props.lnkamigavel} className="podcast-hero-tag" rel="tag"> {this.props.nomecategoria}</a></li>
                <li className="item"><i className="fa fa-clock-o"></i> {this.props.tempo} mins.</li>
                <li className="item"><a href={'/arquivo/audio' + this.props.idpodcast + '.mp3'} download={'audio' + this.props.idpodcast + '.mp3'} className="podcast-hero-download"><i className="fa fa-download"></i> Download</a></li>
              </ul>
              <ul className="podcast-hero-meta">
                <li className="item"><a href={"/podcast/" + this.props.lnkamigavel} className="podcast-hero-tag" rel="tag"> Por {this.props.autor}</a></li>
              </ul>
            </div>
          </div>
          <div className="podcast-hero-player-content">
            <div className="container">
              <ReactPlayer url={'https://www.promecast.com.br/arquivo/audio' + this.props.idpodcast + '.mp3'} height="50px" width="" controls />
            </div>
          </div>
        </div>
      </section>
    );
  }
}