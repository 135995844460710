
import React, { Component } from 'react';

// jshint ignore: start
export class ItemPodCast extends Component {



  render() {
    let largo = false;
    if (this.props.index === 0 || this.props.tipo === 'lista') {
      largo = true;
    }

    return (

      <div className={largo === true ? 'col-sm-12 mb-40' : 'col-sm-6 mb-40'}>
        <div className={largo === true ? 'podcast-card full' : 'podcast-card boxed'}>
          <figure className="podcast-image"><a href={'/podcast/' + this.props.lnkamigavel}><img src={'https://www.promecast.com.br/arquivo/podcastmini' + this.props.idpodcast + '.jpg'} alt="imagem crianca" title="imagem crianca" /></a></figure>
          <div className="podcast-content">
            <span className="podcast-date">{this.props.data}</span>
            <h2 className="podcast-title"><a href={'/podcast/' + this.props.lnkamigavel}>{this.props.nome}</a></h2> {largo === false && <br></br>}
            <p className="podcast-excerpt"><a href={'/podcast/' + this.props.lnkamigavel}>{this.props.descricao}
            </a></p>{largo === false && <br></br>}
            <ul className="podcast-meta">
              <li className="item"><a href={'/podcastpes/categoria/' + this.props.nomecategoria} className="podcast-tag" rel="tag">{this.props.nomecategoria}</a></li>
              <li className="item"><i className="fa fa-clock-o"></i> {this.props.tempo} mins.</li>
              <li className="item">por {this.props.autor}</li>
            </ul>
          </div>
        </div>
      </div>

    );
  }
}