import React, { Component } from 'react';

import { CardPessoa } from './component/CardPessoa';
import { ItemPodCast } from './component/ItemPodCast';
import $ from 'jquery';
import { Footer } from './Footer';
import { PodPrincipal } from './component/PodPrincipal';

// jshint ignore: start
class App extends Component {

  constructor() {
    super();
    this.state = { podcasts: [], caregamento: false, cadastrounews: false, email: "",pessoasQuemSomos:[] };
    this.enviaNews = this.enviaNews.bind(this);
    this.setEmail = this.setEmail.bind(this);


  }
  componentWillMount() {
    var me = this;

    $.ajax({
      url: "https://www.promecast.com.br/api/podcast/site/top/6",
      dataType: 'json',
      success: function (resposta) {
        // console.log(resposta);
        me.setState({
          podcasts: resposta,
          carregamento: true
        });

      }
    }
    );

    $.ajax({
      url: "https://promecast.com.br/api/pessoa/listaquemsomos",
      dataType: "json",
      success: function (resposta) {
        me.setState({ pessoasQuemSomos: resposta });
      },
    });

  }
  getParsedDate(date) {
    date = String(date).split(' ');
    var days = String(date[0]).split('-');;
    return days[2] + '/' + days[1] + '/' + days[0];
  }
  setEmail(evento) {
    // console.log(evento.target.value);
    this.setState({ email: evento.target.value });
  }

  enviaNews(evento) {
    evento.preventDefault();

    this.setState({ cadastrounews: true });
    $.ajax({
      url: 'https://www.promecast.com.br/api/newsletter/' + this.state.email,
      contentType: 'application/json',
      dataType: 'json',
      type: 'post',
      data: JSON.stringify({ email: '" + this.state.email + "' }),
      success: function (resposta) {
        console.log("enviado com sucesso");
      }.bind(this),
      error: function (resposta) {
        console.log("erro");
      }
    });

  }
  render() {

    const primeiropod = this.state.podcasts[0];
    this.state.podcasts.slice(0, 1)
    return (

      <any>

        {this.state.carregamento &&
          <PodPrincipal
            nome={primeiropod.nome}
            descricao={primeiropod.miniartigo}
            data={this.getParsedDate(primeiropod.data)}
            tempo={primeiropod.tempo}
            idpodcast={primeiropod.idpodcast}
            nomecategoria={primeiropod.nomecategoria}
            lnkamigavel={primeiropod.lnkamigavel}
            autor={primeiropod.autor}
          ></PodPrincipal>
        }
        <main id="main" className="main">


          <section id="#episodes" className="section-positive">
            <div className="container">

              <h2 className="title-default">Episódios anteriores</h2>

              <div className="row">

                {
                  this.state.podcasts.map((p, index) => {
                    if (index > 0) {


                      return (

                        <ItemPodCast
                          key={p.idpodcast}
                          index={index - 1}
                          tipo="principal"
                          nome={p.nome}
                          descricao={p.miniartigo}
                          data={this.getParsedDate(p.data)}
                          tempo={p.tempo}
                          idpodcast={p.idpodcast}
                          nomecategoria={p.nomecategoria}
                          lnkamigavel={p.lnkamigavel}
                          autor={p.autor}
                        >
                        </ItemPodCast>

                      );
                    }
                  })
                }

                <div className="col-sm-12 mb-50">
                  <a href="/podcast" className="btn btn-primary btn-block btn-lg">Mais Episodios</a>
                </div>

              </div>

            </div>
          </section>
        </main>
        <section id="about-us" className="section-negative">
          <div className="container">

            <h2 className="title-default text-center">Quem somos...</h2>

            <div className="row mb-30">

                  {this.state.pessoasQuemSomos.map((p) => {
                return (
                  <CardPessoa
                    key={p.idpessoa}
                    idpessoa={p.idpessoa}
                  ></CardPessoa>
                );
              })}

            </div>

          </div>
        </section>

        <section className="text-center section-highlightLeve" >
          {this.state.cadastrounews === false &&
            <div className="container">
              <h2 className="title-default">INSCREVA-SE EM NOSSA NEWSLETTER!</h2>
              <p className="section-description"></p>
              <form method="post" onSubmit={this.enviaNews} className="form-newsletter validate">
                <fieldset className="row">
                  <div className="col-md-9 nopadding">
                    <input type="email" name="EMAIL" className="form-control form-negative" id="email-newsletter" placeholder="Seu Email" value={this.state.email} onChange={this.setEmail} required />
                  </div>
                  <div className="col-md-3 nopadding">
                    <button className="btn btn-success btn-lg btn-block" type="submit"><i className="fa fa-paper-plane"></i></button>
                  </div>
                </fieldset>
              </form>
            </div>
          }
          {this.state.cadastrounews &&
            <div className="container">
              <h2 className="title-default">Cadastro Realizado!!! :)</h2>
            </div>
          }
        </section>

        <Footer></Footer>

      </any>

    );
  }
}

export default App;
