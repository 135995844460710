import React, { Component } from 'react';
import { Footer } from './Footer';

// jshint ignore: start
export class OQueE extends Component {

  render() {
 
    return (
<any>
<main id="main" className="main">			
			<header className="page-header" style={{'backgroundImage' : 'url(assets/image/home3.png)'}}>
				<div className="page-header-inner">
					<div className="container">						
						<ol className="breadcrumb">
							<li itemprop="itemListElement"><a href="/">Home</a></li>
							<li itemprop="itemListElement"><a href="/oquee">O que é o Promecast?</a></li>
						</ol>						
						<div className="page-header-content text-center">
							<h2>O que é o Promecast?</h2>
						</div>

					</div>
				</div>
			</header>			
			<section className="section-negative">				
				<div className="page-content">
					<div className="container">
						<h2 className="title-default mb-30">O que é?</h2>
						<div className="row">
							<div className="col-md-12 mb-20">
								<p>
                                O Promecast é um programa de áudio com conteúdo diretamente relacionado com a Cultura, Conhecimento e a Bíblia.
                                </p>

							</div>

						</div>
						<h2 className="title-default mb-30">Qual o propósito?</h2>
						<div className="row">
							<div className="col-md-12 mb-20">
								<p>
Aproximar os jovens de pontos teológicos no qual cremos e aplicar um olhar mais atento e demorado sobre questões Bíblicas relevantes ao cristianismo moderno.
</p>
							</div>

						</div>
                        <h2 className="title-default mb-30">Como funciona?</h2>
						<div className="row">
							<div className="col-md-12 mb-20">
								<p>
                                Pessoas formadas em áreas diversas conversam entre si sobre um determinado tema. Com trilha musical cristã e efeitos sonoros, tentamos deixar o programa o mais dinâmico possível e com um bom nível de humor. É possível interagir conosco através das mídias sociais.
</p>
							</div>

						</div>
                        <h2 className="title-default mb-30">Por que um PodCast?</h2>
						<div className="row">
							<div className="col-md-12 mb-20">
								<p>
                                Porque você pode ouvir em qualquer lugar. O arquivo é pequeno e você pode ouvir direto da internet, ou fazer o download e escutar no carro, no quarto, no banheiro, no celular, no ônibus, no metrô, no ipod, em qualquer lugar!
</p>
							</div>

						</div>
   <h2 className="title-default mb-30">Não deixe de divulgar aos seus amigos!</h2>					
            </div>
			</div>
            </section>
		</main>
    <Footer></Footer>  
    </any>
    );
  }
}

